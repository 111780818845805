import imgMatt from '../images/testimonials/matt.jpg'
import imgRyan from '../images/testimonials/ryan.jpg'
import imgEvan from '../images/testimonials/evan.jpeg'
import imgDense from '../images/testimonials/densediscovery.svg'
import imgL4u from '../images/testimonials/l4u.jpeg'

const testimonials = [

  {
    name: 'Kai Brach',
    title: 'Dense Discovery',
    quote:
      'What a neat idea: send all the articles or blog posts you’re too busy to read to TAYL and it’ll compile them into an audio version. You then simply subscribe to your very own podcast to listen in any time.',
    img: imgDense,
    kind: 'site',
  },

  {
    name: 'Evan Morrison',
    title: 'Small Business Owner',
    quote:
      'TAYL helps me stay up to date with the news. It also works well for school work, I was able to use it to study for a subject by having it read me my lecture notes while I was on the train.',
    img: imgEvan,
  },

  {
    name: 'logoworks4u',
    title: 'Author',
    quote:
      'I am an author, I use this to listen to my books to help with editing. It is a VERY natural voice reading with lots of options of language, gender, etc. One of the things this software does that others like it does not is the word count. I usually have to break my stories in 20 different sections to import, but I can literally just copy and paste the whole thing into the "Tales", HUGE time saver. The personal email box to send it tales, personal podcast link, and so much more make this product feel very mature.',
    img: imgL4u,
  },

  {
    name: 'Ryan Hoover',
    title: 'Founder of Product Hunt',
    quote: 'Turn websites into a "podcast" 🤔',
    img: imgRyan,
  },

  {
    name: 'Matt Hartman',
    title: 'Partner at Betaworks',
    quote: 'This is super cool, h/t @bradleyengel',
    img: imgMatt,
  },

]

export default testimonials
