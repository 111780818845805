import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import RubberBand from 'react-reveal/RubberBand'
import Layout from '../components/Layout.js'
import Container from '../components/Container.js'
import Footer from '../components/Footer.js'
import stripeBadge from '../images/stripe.svg'
import heroStyles from '../components/Hero.module.sass'
import plans from '../data/plans.js'
import testimonials from '../data/testimonials.js'
import styles from './pricing.module.sass'
import './index.sass'

const memberRoot = 'https://my.tayl.app'

const Testimonial = ({ name, title, img, quote, kind = 'person' }) => (
  <blockquote
    className={classnames(styles.testimonial, styles[kind])}
    key={name}
  >
    <p>“{quote}”</p>
    <footer>
      <div className={styles.profile}>
        <img src={img} alt="" />
      </div>
      <div>
        <span className={styles.user}>{name}</span>
        <span className={styles.title}>{title}</span>
      </div>
    </footer>
  </blockquote>
)

const Voices = () => {
  const [isAnnually, setIsAnnually] = useState(true)
  const [isPriceAnnually, setIsPriceAnnually] = useState(true)

  useEffect(() => {
    const t = setTimeout(() => {
      setIsPriceAnnually(isAnnually)
    }, 430)
    return () => clearTimeout(t)
  }, [isAnnually])

  const handleToggleBilling = () => setIsAnnually(!isAnnually)
  return (
    <Layout>
      <div className={heroStyles.hero}>
        <div className={heroStyles.content}>
          <div>
            <h2>
              Plans and Pricing
              <small>Affordable, flexible and secure.</small>
            </h2>
          </div>
        </div>
        <div className={heroStyles.book} />
      </div>

      <div className="section section--how">
        <Container>
          <div className={styles.plans}>
            {plans.map((plan, i) =>
              renderPlan(
                plan,
                i,
                isAnnually,
                isPriceAnnually,
                handleToggleBilling
              )
            )}
          </div>
          <div className={styles.stripe}>
            <div>
              Secure payments
              <a
                href="https://stripe.com/docs/security/stripe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={stripeBadge} alt="" />
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.testies}>
        <Container>
        <div className={heroStyles.content}>
          <div>
          <h2>Thousands of Happy Customers<small>Hundreds of Different Use-Cases.</small></h2>
        </div>
        </div>
          <div className={styles.testimonials}>
            {testimonials.map(Testimonial)}
          </div>
        </Container>
      </div>

      <Footer />
    </Layout>
  )
}

function renderPlan(
  { id, name, desc, features, quota, img, price },
  i,
  isAnnually,
  isPriceAnnually,
  handleToggleBilling
) {
  let Price = (
    <div className={styles.price}>
      <div className={styles.value}>0</div>
      <ul>
        <li>USD</li>
        <li>per month</li>
        <li>no billing</li>
      </ul>
    </div>
  )

  let Savings = <div className={styles.savings}>Limited 7-day free trial.</div>

  if (price) {
    Price = (
      <RubberBand spy={isAnnually} duration={800}>
        <div className={styles.price}>
          <div className={styles.value}>
            {price[isPriceAnnually ? 'annually' : 'monthly'] /
              (isPriceAnnually ? 12 : 1)}
          </div>
          <ul>
            <li>USD</li>
            <li>per month</li>
            <li>
              billed{' '}
              <button onClick={handleToggleBilling}>
                {isPriceAnnually ? 'annually' : 'monthly'}
              </button>
            </li>
          </ul>
        </div>
      </RubberBand>
    )

    const savings = price.monthly * 12 - price.annually

    Savings = isPriceAnnually ? (
      <div className={styles.savings}>
        You save {Math.floor((savings / (12 * price.monthly)) * 100)}%, that's $
        {savings}/year!
      </div>
    ) : (
      <div className={styles.savings}>
        <button onClick={handleToggleBilling}>Save more</button> with annual
        billing.
      </div>
    )
  }

  const planUrl =
    id === 'plan_free'
      ? memberRoot
      : `${memberRoot}/upgrade/${id}_${
          isPriceAnnually ? 'annually' : 'monthly'
        }`

  const ctaSubtitle =
    id !== 'plan_free' ? '100% satisfaction guarantee' : 'Listen in 2 minutes'

  return (
    <div className={styles.plan} key={id}>
      <div className={styles.main}>
        <div className={styles.hero}>
          <img src={img} alt="" className={styles.planImg} />
          <div className={styles.name}>
            <h3>{name}</h3>
            <p className={styles.desc}>{desc}</p>
          </div>
        </div>
        <div className={styles.pricingInfo}>
          {Price}
          <a href={planUrl} className={styles.cta}>
            Get Started
            <small>{ctaSubtitle}</small>
          </a>
          {Savings}
        </div>
      </div>
      <div className={styles.details}>
        <ul className={styles.features}>{features.map(renderFeature)}</ul>
      </div>
    </div>
  )
}

function renderFeature(body, i) {
  return <li key={i}>{body}</li>
}

export default Voices
