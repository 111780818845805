import React from 'react'
import { Tooltip } from 'react-lightweight-tooltip'
import imgPlanFree from '../images/plan-free.svg'
import imgPlanStandard from '../images/plan-standard.svg'
import imgPlanPremium from '../images/plan-premium.svg'
import {
  numSupportedVoices,
  numSupportedStandardVoicesEn,
  numSupportedLanguages,
} from '../constants.js'

const ttStyles = {
  tooltip: {
    textAlign: 'center',
    borderRadius: '4px',
    fontWeight: 500,
  },
}

const TT = ({ children, ...props }) => (
  <Tooltip styles={ttStyles} {...props}>
    {children}
  </Tooltip>
)

const plans = [
  {
    id: 'plan_free',
    name: 'Free',
    desc: 'No credit card required',
    img: imgPlanFree,
    features: [
      'English language',
      `${numSupportedStandardVoicesEn} voices`,
      'Summarized content',
    ],
    quota: {
      standard: 'N/A',
      premium: 'N/A',
    },
  },
  {
    id: 'plan_standard',
    name: 'Standard',
    desc: 'Designed for the masses',
    img: imgPlanStandard,
    features: [
      <TT content="300k characters (about 5-7 hours of audio), or premium (HQ) voices: 75k characters.">
        <strong>3,000 credits/month</strong>
      </TT>,
      `${numSupportedLanguages}+ languages`,
      `${numSupportedVoices}+ voices`,
      'Unlimited storage',
      '1,500+ integrations',
      'Programmable API',
    ],
    quota: {
      standard: '300,000',
      premium: '75,000',
    },
    price: {
      monthly: 6,
      annually: 48,
    },
  },
  {
    id: 'plan_premium',
    name: 'Premium',
    desc: 'The complete package',
    img: imgPlanPremium,
    features: [
      <TT content="1.2M characters (about 15-25 hours of audio), or premium (HQ) voices: 300k characters.">
        <strong>12,000 credits/month</strong>
      </TT>,
      `${numSupportedLanguages}+ languages`,
      `${numSupportedVoices}+ voices`,
      'Unlimited storage',
      '1,500+ integrations',
      'Programmable API',
    ],
    quota: {
      standard: '1,200,000',
      premium: '300,000',
    },
    price: {
      monthly: 25,
      annually: 108,
    },
  },
]

export default plans
